import React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../../components';
import { googleChat } from '../../../util/googleChat';
import css from './AcceptInviteForm.module.css';

const KEY_CODE_ENTER = 13;

const AcceptInviteFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        onOpenTermsOfService,
      } = fieldRenderProps;

      // referer details
      let urlParams = null;
      let invitedEmail = null;
      let invitedCompanyName = null;
      let refererName = null;
      let refererEmail = null;
      let refererCompanyName = null;
      let refererUUID = null;
      let invitedUserType = null;
      let inviteId = null;
      let inviteTempEmail = null;

      if (typeof window !== 'undefined') {
        urlParams = new URLSearchParams(decodeURIComponent(window.location.search));
        invitedEmail = urlParams.get('ie') ? urlParams.get('ie').replace(' ', '+') : null;
        invitedCompanyName = urlParams.get('icn') ?? null;
        refererName = urlParams.get('rn') ?? null;
        refererEmail = urlParams.get('re') ?? null;
        refererCompanyName = urlParams.get('rcn') ?? null;
        refererUUID = urlParams.get('ruuid') ?? null;
        invitedUserType = urlParams.get('iut') ?? null;
        inviteId = urlParams.get('iid') || null;
        inviteTempEmail = urlParams.get('ite') || null;
      }

      const sendGoogleChatNotification = () => {
        if (invitedEmail !== null) {
          googleChat('user-visit.invited', { invitedEmail, refererEmail });
        }
      };
      useEffect(
        sendGoogleChatNotification,
        [] // <-- empty dependency array
      );

      // email
      const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordsMustMatch = intl.formatMessage({
        id: 'AcceptInviteForm.passwordsMustMatch',
      });
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

      const passwordsMatch = validators.passwordsMatch(passwordsMustMatch);

      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      const companyNameRequiredMessage = 'Company name is required';
      const companyNameRequired = validators.required(companyNameRequiredMessage);

      const deliveryAddressRequiredMessage = 'Delivery address is required';
      const deliveryAddressRequired = validators.required(deliveryAddressRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </span>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit} data-cy="acceptInviteForm">
          <h2 className={css.header}>
            <FormattedMessage id="AcceptInviteForm.header.youhavebeen.line1" />
            <br />
            <FormattedMessage id="AcceptInviteForm.header.youhavebeen.line2" />
          </h2>
          <h3 className={css.header}>
            <FormattedMessage id="AcceptInviteForm.header.uksfirst.line1" />
          </h3>
          <h3 className={css.header}>
            <FormattedMessage id="AcceptInviteForm.header.uksfirst.line2" />
          </h3>
          <div className={css.invitedBy}>
            <ul className={css.invitedBy}>
              <li>
                Invited by{' '}
                <span>
                  {refererName} of {refererCompanyName}
                </span>
              </li>
            </ul>
          </div>

          <div className={css.formContainer}>
            <h3>Your email - {invitedEmail}</h3>
            <div>
              <div className={css.hiddenFields}>
                <FieldTextInput
                  type="email"
                  id={formId ? `${formId}.email` : 'email'}
                  name="email"
                  label={emailLabel}
                  placeholder={emailPlaceholder}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                  defaultValue={invitedEmail}
                />
                <FieldTextInput
                  type="text"
                  id={formId ? `${formId}.refererEmail` : 'refererEmail'}
                  name="refererEmail"
                  defaultValue={refererEmail}
                />
                <FieldTextInput
                  type="text"
                  id={formId ? `${formId}.refererName` : 'refererName'}
                  name="refererName"
                  defaultValue={refererName}
                />
                <FieldTextInput
                  type="text"
                  id={formId ? `${formId}.refererCompanyName` : 'refererCompanyName'}
                  name="refererCompanyNAme"
                  defaultValue={refererCompanyName}
                />
                <FieldTextInput
                  type="text"
                  id={formId ? `${formId}.refererUUID` : 'refererUUID'}
                  name="refererUUID"
                  defaultValue={refererUUID}
                />
                <FieldTextInput
                  type="text"
                  id={formId ? `${formId}.invitedUserType` : 'invitedUserType'}
                  name="invitedUserType"
                  defaultValue={invitedUserType}
                />
                <FieldTextInput
                  type="text"
                  id={formId ? `${formId}.inviteId` : 'inviteId'}
                  name="inviteId"
                  defaultValue={inviteId}
                />
                <FieldTextInput
                  type="text"
                  id={formId ? `${formId}.inviteTempEmail` : 'inviteTempEmail'}
                  name="inviteTempEmail"
                  defaultValue={inviteTempEmail}
                />
              </div>
            </div>
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                validate={firstNameRequired}
                data-cy="acceptInviteFormFirstName"
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                validate={lastNameRequired}
                data-cy="acceptInviteFormFamilyName"
              />
            </div>
            <div className={css.name}>
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.invitedCompanyName` : 'invitedCompanyName'}
                name="invitedCompanyName"
                defaultValue={invitedCompanyName}
                label="Company name"
                placeholder="Company name"
                validate={companyNameRequired}
                data-cy="acceptInviteFormCompanyName"
              />
            </div>

            <div className={css.name}>
              <FieldTextInput
                className={css.lastNameRoot}
                type="textarea"
                id={formId ? `${formId}.invitedDeliveryAddress` : 'invitedDeliveryAddress'}
                name="invitedDeliveryAddress"
                label="Delivery Address"
                validate={deliveryAddressRequired}
                data-cy="acceptInviteFormInvitedDeliveryAddress"
                placeholder="Delivery Address"
              />
            </div>

            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={passwordLabel}
              placeholder={passwordPlaceholder}
              validate={passwordValidators}
              data-cy="acceptInviteFormPassword"
            />

            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.confirmPassword` : 'confirmPassword'}
              name="confirmPassword"
              label="Confirm password"
              placeholder="Confirm password"
              validate={passwordsMatch}
              data-cy="acceptInviteFormConfirmPassword"
            />
          </div>
          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FormattedMessage
                  id="SignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink }}
                />
              </span>
            </p>
            <PrimaryButton
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              data-cy="buttonAcceptInvite"
            >
              <FormattedMessage id="AcceptInviteForm.accept" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

AcceptInviteFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

AcceptInviteFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const AcceptInviteForm = compose(injectIntl)(AcceptInviteFormComponent);
AcceptInviteForm.displayName = 'AcceptInviteForm';

export default AcceptInviteForm;
